import PropTypes from 'prop-types'

function PlayItemItem({ playItem, handleItemClicked }) {
    const {
        playerName,
        eventString,
        userDefined01,
      } = playItem
    
    const handleClicked = () =>
    {
        handleItemClicked(playItem)
    }

    // console.log('handleItemClicked', handleItemClicked)
    return (
        <>
            <div className='my-1 rounded-sm card-compact bg-base-200 hover:bg-base-300' onClick={handleClicked}>
                <div className='px-2 flex'>
                    <p className='text-left text-sm font-bold text-base-900'>
                        {playerName === "(null)" ? "-" : playerName}
                    </p>
                </div>
                <div className='px-2 flex'>
                    <p className='text-left text-sm font-normal text-base-900'>
                        {eventString === "(null)" ? "-" : eventString} 
                    </p>
                </div>
                <div className='px-2 flex'>
                    <p className='text-left text-xs font-normal text-blue-400'>
                        {userDefined01}
                    </p>
                </div>
            </div>
        </>
    )
}

PlayItemItem.propTypes = {
  playItem: PropTypes.object.isRequired,
}

export default PlayItemItem
