import PropTypes from 'prop-types'
import SetItem from './SetItem'

function SetList({ sets, handleItemClicked }) {
  // console.log('SetList sets', sets)

  return (
    <div className='rounded-lg shadow-lg card bg-base-100'>
      <div className='card-body'>
        {sets.map((set) => (
          <SetItem key={set.id} set={set}
            handleItemClicked={(videoTime) => handleItemClicked(videoTime)}
          />
        ))}
      </div>
    </div>
  )
}

SetList.propTypes = {
  sets: PropTypes.array.isRequired,
}

export default SetList
