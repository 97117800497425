import PropTypes from 'prop-types'
import PlayItemList from '../playItems/PlayItemList'

function PlayListItem({ playList, handleItemClicked }) {
  const {
    name,
  } = playList

  console.log('PlayListItem', name)

  return (
    <div className='text-lg px-2 my-4 rounded-lg text-center font-bold bg-base-150 text-base-400'>
      <div className="pb-2">
        {name.toUpperCase()}
      </div>
        <PlayItemList 
          playItems={playList.playItems}
          handleItemClicked={(playItem) => handleItemClicked(playItem)} 
        />
    </div>
  )
}

PlayListItem.propTypes = {
  playList: PropTypes.object.isRequired,
}

export default PlayListItem
