const tennisstatsapiReducer = (state, action) => {
    switch (action.type) {
      case 'GET_PLAYERS':
        return {
          ...state,
          players: action.payload,
          loading: false,
        }
      case 'GET_PLAYER_AND_MATCHES':
        return {
          ...state,
          player: action.payload.player,
          matches: action.payload.matches,
          loading: false,
        }
        case 'GET_MATCH_AND_SETS':
          return {
            ...state,
            match: action.payload.match,
            sets: action.payload.sets,
            loading: false,
          }
          case 'GET_MATCHES_AND_SETS':
            return {
              ...state,
              matches: action.payload.matches,
              sets: action.payload.sets,
              loading: false,
            }
          case 'GET_PLAYLISTS':
          return {
            ...state,
            playLists: action.payload.playLists,
            loading: false,
          }      
        case 'SET_LOADING':
        return {
          ...state,
          loading: true,
        }
      case 'CLEAR_PLAYERS':
        return {
          ...state,
          players: [],
        }
      default:
        return state
    }
  }
  
  export default tennisstatsapiReducer
  