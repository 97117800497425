import PropTypes from 'prop-types'
import PlayItemItem from './PlayItemItem'

function PlayItemList({ playItems, handleItemClicked }) {
    // console.log('PlayItemList playItems', playItems)

    return (
        <div className='rounded-lg shadow-lg'>
            <div className='bg-base-100'>
                {playItems.map((playItem) => (
                    < PlayItemItem 
                    key = { playItem.id } 
                    playItem = { playItem } 
                    handleItemClicked = {(playItem) => handleItemClicked(playItem)} 
                    />
                ))}
            </div>
        </div>
    )
}

PlayItemList.propTypes = {
    playItems: PropTypes.array.isRequired,
}

export default PlayItemList
