import { useEffect, useContext, useRef, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import Spinner from '../components/layout/Spinner'
import PlayListList from '../components/playLists/PlayListList'
import TennisStatsAPIContext from '../context/tennisstatsapi/TennisStatsAPIContext'
import { getPlayListsForPlayer } from '../context/tennisstatsapi/TennisStatsAPIAction'
import ReactPlayer from 'react-player'
import TennisEventContext from '../context/tennisevent/TennisEventContext'
import Hamburger from 'hamburger-react'

function Playlist() {
  const [url, setUrl] = useState(null)
  const [playItem, setPlayItem] = useState(null)
  const [pendingPlayItem, setPendingPlayItem] = useState(null)

  const load = url => {
    this.setState({
      url,
    })
  }

  const { loading, playLists, dispatch } = useContext(TennisStatsAPIContext)
  const { tennisEvent } = useContext(TennisEventContext)
  const params = useParams()
  const playerRef = useRef()

  useEffect(() => {
    document.getElementById('my-drawer-4').checked = true
    dispatch({ type: 'SET_LOADING' })
    const getPlaylistData = async () => {
      const playlistData = await getPlayListsForPlayer(params.playerId)
      dispatch({ type: 'GET_PLAYLISTS', payload: playlistData })
    }
    getPlaylistData()
  }, [dispatch, params.playerId])

  const {
    name,
    playItems,
  } = playLists

  if (loading) {
    return <Spinner />
  }

  // console.log('Playlist.jsx playLists', playLists)

  const playItemSelected = (playItem) => {

    sm: closePlaylistsList()

    setPlayItem(playItem)
    // console.log('Playlist.jsx', playItem)
    // console.log('current URL', playerRef.current, playerRef.current.url)
    const newurl = playItem.videoFile.replace('m3u8', 'mp4')
    // console.log('Playlist.jsx new URL', newurl)
    if (newurl !== url) {
      setPendingPlayItem(playItem)
      setUrl(newurl)
    }
    playerRef.current.playing = 'true'
    playerRef.current.seekTo(playItem.startLocation, "seconds");
    playerRef.current.playing = true
  }

  const playerReady = () => {
    if (pendingPlayItem != null) {
      playerRef.current.seekTo(pendingPlayItem.startLocation, "seconds");
      setPendingPlayItem(null)
    }
  }

  const closePlaylistsList = () => {
    document.getElementById('my-drawer-4').checked = false
  }

  const showPlaylistsList = () => {
    document.getElementById('my-drawer-4').checked = true
  }

  return (
    <>
      <div className="drawer drawer-mobile">
        <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          <div className="flex flex-col w-full justify-between">
            <div className="flex w-full pl-2 rounded-lg card-compact bg-base-300 hover:bg-base-300">
              <div className='md:hidden'>
                <img className='mr-2 pt-2' alt="" width={32}
                  src={require(`../components/layout/assets/hamburger_button.png`)}
                  onClick={() => showPlaylistsList()}
                />
              </div>
              <div className=''>
                <p className='px-2 pt-2 text-xs font-semibold md:text-lg'>
                  {playItem ? playItem.playListName.toUpperCase() : null}
                </p>
                <div className='px-2 pb-2 text-xs text-blue-400 text-xs md:text-md'>
                  {playItem ? playItem.userDefined01 : null}
                </div>
                <div className='px-2 pb-2 text-xs md:text-md'>
                  {playItems && playItem.notes !== '(null)' ? playItem.notes : null}
                </div>
              </div>
            </div>
            <div className='flex justify-center my-4'>
              <ReactPlayer ref={playerRef}
                url={url}
                playing='true'
                width="90%"
                height="90%"
                controls={true}
                onReady={() => playerReady()}
              />
            </div>
          </div>
        </div>
        <div className="drawer-side h-full">
          <label htmlFor="my-drawer-4" className="drawer-overlay"></label>
          <div className="overflow-y-auto w-80 bg-base-100">
            <PlayListList playLists={playLists}
              handleItemClicked={(playItem) => playItemSelected(playItem)} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Playlist