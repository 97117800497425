import React from 'react'
import PlayerResults from '../components/players/PlayerResults'
import PlayerSearch from '../components/players/PlayerSearch'

function Home() {
  return (
    <div>
        <PlayerSearch />
        <PlayerResults />
    </div>
  )
}

export default Home