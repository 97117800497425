import { useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Spinner from '../components/layout/Spinner'
import MatchList from '../components/matches/MatchList'
import TennisStatsAPIContext from '../context/tennisstatsapi/TennisStatsAPIContext'
import { getPlayerAndMatches, getPlayListsForPlayer } from '../context/tennisstatsapi/TennisStatsAPIAction'

function Player() {
  const navigate = useNavigate();
  const params = useParams()
  const { player, loading, matches, playLists, dispatch } = useContext(TennisStatsAPIContext)

  useEffect(() => {
    dispatch({ type: 'SET_LOADING' })
    const getPlayerData = async () => {
      const playerData = await getPlayerAndMatches(params.playerId)
      dispatch({ type: 'GET_PLAYER_AND_MATCHES', payload: playerData })

      const playlistData = await getPlayListsForPlayer(params.playerId)
      dispatch({ type: 'GET_PLAYLISTS', payload: playlistData })

    }
    getPlayerData()
    // const getPlaylistData = async () => {
    //   const playlistData = await getPlayListsForPlayer(params.playerId)
    //   dispatch({ type: 'GET_PLAYLISTS', payload: playlistData })
    // }
    // getPlaylistData()
    // console.log('playLists', playLists)
  }, [dispatch, params.playerId])

  const {
    id,
    firstName,
    lastName,
    notes,
    countryCode,
    imageData,
    faceData,
  } = player

  if (loading) {
    return <Spinner />
  }

  const showPlayList = () =>
  {
    navigate(`/playlist/${id}`)    
  }

  const closeMatchesList = () => {
    document.getElementById('my-drawer-2').checked = false
  }

  const showMatchesList = () => {
    document.getElementById('my-drawer-2').checked = !document.getElementById('my-drawer-2').checked
  }

  // console.log('Player.jsx player', player)
  // console.log('Player.jsx playList', playLists)
  if (firstName == null) {
    return (<></>)
  }

  return (
    <>
      <div className="drawer drawer-mobile h-full">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle w-80" />
        <div className="drawer-content flex flex-col items-center justify-center">
          <div className="flex-none w-full h-full pl-4 pt-1">
            <div className="flex justfify-between w-full pl-2 rounded-lg card-compact bg-base-300 hover:bg-base-300">
              <div className='lg:hidden'>
                <img className='mr-2 pt-1 w-10 md:pt-2' alt="" width={32}
                  src={require(`../components/layout/assets/hamburger_button.png`)}
                  onClick={() => showMatchesList()}
                />
              </div>
              <div className="flex flex-col">
                <div className='flex'>
                  <p className='pl-2 pt-2 text-sm font-normal md:text-3xl'>
                    {player !== null ? firstName.toUpperCase() : null}
                  </p>
                  <p className='px-2 pt-2 text-sm font-bold md:text-3xl'>
                    {player !== null ? lastName.toUpperCase() : null}
                  </p>
                </div>
                <div className='flex'>
                  <div>
                    {player !== null && countryCode !== null ? <img className='pl-2 w-7 md:w-9' alt="" src={require(`../components/layout/assets/flags/${player.countryCode}.png`)} /> : ""}
                  </div>
                  <p className='px-2 text-sm font-bold md:text-xl'>
                    {player !== null && countryCode !== null ? countryCode : null}
                  </p>
                </div>
              </div>

              <div className="ml-auto mr-2">
                {playLists.length > 0 ?
                  <img className='pt-1 w-10 md:pt-2' alt="" width={32}
                    src={require(`../components/layout/assets/playlist_button.png`)}
                    onClick={() => showPlayList()}
                  />
                  : ""
                }
              </div>
            </div>

            <div className="h-3/4">
              <div className="flex flex-col md:flex-row">
                <div className="mb-4 md:w-1/3 md:mt-1">
                  <img className="mx-auto rounded-t-lg md:rounded-none max-h-48 md:max-h-full"
                    src={`data:image/jpeg;base64,${imageData}`} alt="" />
                </div>
                <div className="flex-initial h-[60vh] px-4 whitespace-pre-line text-md text-justify text-base-content overflow-auto md:w-2/3">
                  {notes}
                </div>
              </div>
            </div>

          </div>

        </div>
        <div className="drawer-side h-full">
          <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
          <div className="overflow-y-auto w-80 bg-base-100">
            <MatchList
              matches={matches}
              playerId={player.id}
              closeMatchesList={() => closeMatchesList()} />
          </div>
        </div>
      </div>

    </>
  )
}

export default Player
