import { useEffect, useContext, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Spinner from '../components/layout/Spinner'
import TennisCourt from '../components/reports/TennisCourt'
import TennisStatsAPIContext from '../context/tennisstatsapi/TennisStatsAPIContext'
import { getMatchesAndSets } from '../context/tennisstatsapi/TennisStatsAPIAction'
import AllFiltersPanel from '../components/reports/AllFiltersPanel'
import { allFilters } from "../components/reports/AllFilters";

function FiltersAnalysis() {
    const params = useParams()
    // console.log(params)
    const { matches, loading, sets, dispatch } = useContext(TennisStatsAPIContext)
    const [selectedEvents, setSelectedEvents] = useState([])
    const navigate = useNavigate();

    const [, forceUpdate] = useState(0);
    const [allOptions, setAllOptions] = useState(allFilters)
    const [displayMode, setDisplayMode] = useState(0)
    const [isNormalised, setIsNormalised] = useState(false)
    const [showSource, setShowSource] = useState(false)

    const doUpdateAllOptions = (updatedAllOptions) => {
        setAllOptions(updatedAllOptions)
        console.log('updated allOptions', allOptions)
    }

    const doUpdate = () => {
        forceUpdate(n => !n)
    }

    useEffect(() => {
        // console.log('Filtersanalysis allOptions', allOptions)
        dispatch({ type: 'SET_LOADING' })
        const getMatchesData = async () => {
            const matchesData = await getMatchesAndSets(params.matchIds)
            dispatch({ type: 'GET_MATCHES_AND_SETS', payload: matchesData })
        }
        getMatchesData()
        console.log('getMatchesData')
        forceUpdate(n => !n)
    }, [dispatch, params.matchIds])

    if (loading) {
        return <Spinner />
    }

    const handleFilteredEventsChanged = (filteredevents) =>
    {
        setSelectedEvents(filteredevents)
    }
    
    const doFiltersVideo = (tennisevents) => {
        navigate('/filtersvideo',{state:{tennisevents:tennisevents}})
        // navigate(`/filtersvideo/${tennisevents}`)
    }

    const doDisplayMode = (dm) => {
        setDisplayMode(dm)
        // forceUpdate(n => !n)
    }

    const toggleFilters = () => {
        document.getElementById('my-drawer-5').checked = !document.getElementById('my-drawer-5').checked
    }

    const toggleNormalise = () => {
        var n = isNormalised
        n = !n
        setIsNormalised(n)
    }

    const toggleShowSource = () => {
        var n = showSource
        n = !n
        setShowSource(n)
    }

    if (matches === null)
    {
        return <></>
    }

    return (
        <div>
            <div className="drawer drawer-mobile">
                <input id="my-drawer-5" type="checkbox" className="drawer-toggle" />
                <div className="drawer-content">
                    <div className="flex space-x-2 justify-end">
                        <label className="btn" onClick={() => toggleNormalise()}>
                            {
                                isNormalised ? 
                                <img className='w-8 h-8' alt="" src={require(`../components/layout/assets/unnormalised_button.png`)} /> :
                                <img className='w-8 h-8' alt="" src={require(`../components/layout/assets/normalised_button.png`)} />                                
                            }
                        </label>
                        <label className="btn" onClick={() => toggleShowSource()}>
                            <img className='w-8 h-8' alt="" src={require(`../components/layout/assets/toggle_source_button.png`)} />
                        </label>
                        <div className="dropdown tooltip tooltip-bottom" data-tip="Display Type">
                            <label tabIndex={0} className="btn">
                                <img className='w-8 h-8' alt="" src={require(`../components/layout/assets/hamburger_button.png`)} />
                            </label>
                            <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                                <li onClick={() => doDisplayMode(0)}><a>Ball Path</a></li>
                                <li onClick={() => doDisplayMode(1)}><a>Heatmap</a></li>
                                <li onClick={() => doDisplayMode(2)}><a>Zone Count</a></li>
                                <li onClick={() => doDisplayMode(3)}><a>Zone Percent</a></li>
                            </ul>
                        </div>
                        <label className="btn" onClick={() => doFiltersVideo(selectedEvents)}>
                            <img className='w-8 h-8' alt="" src={require(`../components/layout/assets/playlist_button.png`)} />
                        </label>
                        <label className="btn" onClick={() => toggleFilters()}>
                            <img className='w-8 h-8' alt="" src={require(`../components/layout/assets/filter_button.png`)} />
                        </label>
                    </div>
                    {/* <label htmlFor="my-drawer-5" className="flex justify-end btn drawer-button lg:hidden">Toggle Filters</label> */}
                    <div className="h-full bg-blue-500">
                        <TennisCourt
                            sets={sets} allOptions={allOptions}
                            matches={matches}
                            playerId={parseInt(params.playerId, 10)}
                            oppositionId={matches.length === 1 ? matches[0].player1Id.toString() === params.playerId ? matches[0].player2Id : matches[0].player1Id : 0}
                            displayMode={displayMode}
                            isNormalised={isNormalised}
                            showSource={showSource}
                            handleFilteredEventsChanged={(filteredevents) => handleFilteredEventsChanged(filteredevents)}
                        />
                    </div>
                </div>
                <div className="drawer-side w-80">
                    <label htmlFor="my-drawer-5" className="drawer-overlay"></label>
                    <div className="h-full bg-base-200">
                        <AllFiltersPanel
                            allOptions={allOptions}
                            matches={matches}
                            playerId={parseInt(params.playerId, 10)}
                            handleFilterOptionChanged={() => doUpdate()}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FiltersAnalysis
