import PropTypes from 'prop-types'
import EventItem from './EventItem'

function EventList({ tennisevents, handleItemClicked }) {
    // console.log('EventList tennisevents', tennisevents)

    return (
        <div className='rounded-sm shadow-lg bg-base-100'>
            <div className=''>
                {tennisevents.map((tennisevent) => (
                    <EventItem
                        key={tennisevent.id}
                        tennisevent={tennisevent}
                        handleItemClicked={(tennisevent) => handleItemClicked(tennisevent.videoTime)}
                    />
                ))}
            </div>
        </div>
    )
}

EventList.propTypes = {
    tennisevents: PropTypes.array.isRequired,
}

export default EventList
