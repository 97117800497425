import { createContext, useReducer } from 'react'
import tennisstatsapiReducer from './TennisStatsAPIReducer'

const TennisStatsAPIContext = createContext()

export const TennisStatsAPIProvider = ({ children }) => {
  const initialState = {
    players: [],
    matches: [],
    sets: [],
    playLists: [],
    player: {},
    match: {},
    loading: false,
  }

  const [state, dispatch] = useReducer(tennisstatsapiReducer, initialState)

  return (
    <TennisStatsAPIContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </TennisStatsAPIContext.Provider>
  )
}

export default TennisStatsAPIContext
