import { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import EventItem from '../events/EventItem'
import ReactPlayer from 'react-player'
import TennisEventContext from '../../context/tennisevent/TennisEventContext'

function FiltersVideo() {
    const location = useLocation()
    const playerRef = useRef()
    const [url, setUrl] = useState(null)
    const [currentEvent, setCurrentEvent] = useState(null)
    const [pendingEvent, setPendingEvent] = useState(null)

    const goToVideoPosition = (videoTime) => {
        // console.log('Match TS', videoTime)
        // console.log('Match TS', match)
        playerRef.current.seekTo(videoTime, "seconds");
    }

    const eventSelected = (tennisevent) => {

        sm: closeEventsList()

        setCurrentEvent(tennisevent)
        // console.log('Playlist.jsx', playItem)
        // console.log('current URL', playerRef.current, playerRef.current.url)
        const newurl = process.env.REACT_APP_TENNISAUSTRALIA_VIDEO_URL + tennisevent.matchGuid + '.mp4'
        if (newurl !== url) {
            setPendingEvent(tennisevent)
            setUrl(newurl)
        }
        playerRef.current.seekTo(tennisevent.videoTime - 3, "seconds");
        playerRef.current.playing = true
    }

    const playerReady = () => {
        if (pendingEvent != null) {
            playerRef.current.seekTo(pendingEvent.videoTime - 3, "seconds");
            setPendingEvent(null)
        }
    }

    const closeEventsList = () => {
        document.getElementById('my-drawer-3').checked = false
    }

    const showEventsList = () => {
        console.log(location.state)
        document.getElementById('my-drawer-3').checked = true
    }

    // if (player1LastName == null) {
    //     return <></>
    // }

    return (
        <>
            <div className="drawer drawer-mobile">
                <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
                <div className="drawer-content">
                    <div className="flex flex-col w-full justify-between">
                        <div className="flex w-full pl-2 rounded-lg card-compact bg-base-300 hover:bg-base-300">
                            <div className='md:hidden'>
                                <img className='mr-2 pt-1' alt="" width={32}
                                    src={require(`../layout/assets/hamburger_button.png`)}
                                    onClick={() => showEventsList()}
                                />
                            </div>
                            <div className='flex flex-col'>
                                <div className='flex'>
                                    <div className='flex'>
                                        <p className='pl-2 pt-0 text-sm font-normal md:text-lg'>
                                            {currentEvent && currentEvent.firstName.toUpperCase()}
                                        </p>
                                        <p className='px-2 pt-0 text-sm font-bold md:text-lg'>
                                            {currentEvent && currentEvent.lastName.toUpperCase()}
                                        </p>
                                    </div>
                                </div>
                                <div className='flex'>
                                    <p className='px-2 pt-0 text-sm font-medium md:text-lg'>
                                        {currentEvent && currentEvent.eventString.toUpperCase()}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center my-4'>
                            <ReactPlayer ref={playerRef}
                                url={url}
                                autoPlay='true'
                                width="90%"
                                height="90%"
                                controls={true}
                                onReady={() => playerReady()}
                            />
                        </div>
                    </div>
                </div>
                <div className="drawer-side h-full">
                    <label htmlFor="my-drawer-3" className="drawer-overlay"></label>
                    <div className="overflow-y-auto w-80 bg-base-100">
                        {location.state.tennisevents !== null ? location.state.tennisevents.map((tennisevent) => (
                            <EventItem
                                key={tennisevent.id}
                                tennisevent={tennisevent}
                                handleItemClicked={(tennisevent) => eventSelected(tennisevent)}
                            />
                        )) : null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FiltersVideo
