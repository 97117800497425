import axios from 'axios'
const TENNISSTATS_API_URL = process.env.REACT_APP_TENNISSTATS_API_URL

const tennisstatsapi = axios.create({
  baseURL: TENNISSTATS_API_URL,
})

// Get search results
export const searchPlayers = async (text) => {
  const params = new URLSearchParams({
    text: text,
  })

  console.log('Action text', text)

  const response = await tennisstatsapi.get(`Player/SearchPlayer?${params}`)
  return response.data
}

const dicPlayerAndMatches = []
// Get player and matches
export const getPlayerAndMatches = async (playerId) => {
  const params = new URLSearchParams({
    playerId: playerId,
  })

  console.log('Action playerId', playerId)

  var playerData = null
  for (var i=0; i<dicPlayerAndMatches.length; i++)
  {
    var pd = dicPlayerAndMatches[i]
    if (pd.playerId === playerId)
    {
      playerData = pd
      break
    }
  }
  if (playerData == null)
  {
    const [player, matches] = await Promise.all([
      tennisstatsapi.get(`/Player/GetPlayer?${params}`),
      tennisstatsapi.get(`/Match/GetMatchesForPlayer?${params}`),
    ])
    playerData = { playerId: playerId, player: player.data[0], matches: matches.data }
    dicPlayerAndMatches.push(playerData)
    console.log('fetch new', playerData)
    return playerData
  }
  else
  {
    console.log('use cache', playerData)
    return playerData
  }
  // console.log('player.data', player.data)
  // console.log('matches.data', matches.data)

}

// Get match sets
const dicMatchAndSets = []
export const getMatchAndSets = async (matchId) => {
  const params = new URLSearchParams({
    matchId: matchId,
  })

  console.log('Action matchId', matchId)

  var matchData = null
  for (var i=0; i<dicMatchAndSets.length; i++)
  {
    var md = dicMatchAndSets[i]
    if (md.matchId === matchId)
    {
      matchData = md
      break
    }
  }
  if (matchData == null)
  {
    console.log('fetch new getMatchAndSets')
    const [match, sets] = await Promise.all([
      tennisstatsapi.get(`/Match/GetMatch?${params}`),
      tennisstatsapi.get(`/Match/GetSetsForMatch?${params}`),
    ])
    matchData = { matchId: matchId, match: match.data[0], sets: sets.data }
    dicMatchAndSets.push(matchData)
    return matchData
  }
  else
  {
    console.log('use cache getMatchAndSets')
    return matchData
  }
}

// Get sets of matches
const dicMatchesAndSets = []
export const getMatchesAndSets = async (matchIds) => {
  const params = new URLSearchParams({
    matchIds: matchIds,
  })

  console.log('Action matchIds', matchIds)
  console.log('Action dic length', dicMatchesAndSets, dicMatchesAndSets.length)

  var matchesData = null
  dicMatchesAndSets.map((md, index) => {
    console.log('Action md', md)
    if (md.matchIds === matchIds)
    {
      matchesData = md
    }
  })
  // for (var i=0; i<dicMatchesAndSets.length; i++)
  // {
  //   var md = dicMatchesAndSets[i]
  //   console.log('Action md', md)
  //   if (md.matchIds === matchIds)
  //   {
  //     matchesData = md
  //     break
  //   }
  // }
  console.log('Action matchesData', matchesData)
  if (matchesData == null)
  {
    const [matches, sets] = await Promise.all([
      tennisstatsapi.get(`/Match/GetMatches?${params}`),
      tennisstatsapi.get(`/Match/GetSetsForMatches?${params}`),
    ])
    matchesData = { matchIds: matchIds, matches: matches.data, sets: sets.data }
    dicMatchesAndSets.push(matchesData)
    return { matches: matches.data, sets: sets.data }
  }
  else
  {
    console.log('use cache')
    return matchesData
  }
}

// Get play lists
const dicPlayListsForPlayer = []
export const getPlayListsForPlayer = async (playerId) => {
  const params = new URLSearchParams({
    playerId: playerId,
  })

  console.log('Action playerId', playerId)

  var playlistData = null
  for (var i=0; i<dicPlayListsForPlayer.length; i++)
  {
    var pd = dicPlayListsForPlayer[i]
    if (pd.playerId == playerId)
    {
      playlistData = pd
      break
    }
  }

  if (playlistData == null)
  {
    console.log('fetch new getPlayListsForPlayer')
    const response = await tennisstatsapi.get(`PlayList/GetPlayListsForPlayer?${params}`)
    playlistData = { playerId: playerId, playLists:response.data }
    dicPlayListsForPlayer.push(playlistData)
    return playlistData
  }
  else
  {
    console.log('use cache getPlayListsForPlayer')
    return playlistData
  }
}

