import { createContext, useReducer } from 'react'
import tennisEventReducer from './TennisEventReducer.js'

const TennisEventContext = createContext()

export const TennisEventProvider = ({ children }) => {
  const initialState = null

  const [state, dispatch] = useReducer(tennisEventReducer, initialState)

  // Set an tennisevent
  const setTennisEvent = (timestamp, type) => {
    dispatch({
      type: 'SET_TENNISEVENT',
      payload: { timestamp, type },
    })
  }

  return (
    <TennisEventContext.Provider value={{ tennisEvent: state, setTennisEvent }}>
      {children}
    </TennisEventContext.Provider>
  )
}

export default TennisEventContext
