import PropTypes from 'prop-types'

function EventItem({ tennisevent, handleItemClicked }) {
  const {
    id,
    videoTime,
    playerId,
    hand,
    skill,
    subskill,
    eventString,
    firstName,
    lastName,
    realScores,
    result,
  } = tennisevent

  const handleClicked = () => {
    handleItemClicked(tennisevent)
  }

  return (
    <>
      <div className='mb-2 w-full rounded-sm card-compact bg-base-200 hover:bg-base-300' onClick={handleClicked}>
        <div className='flex px-1 space-x-1'>
          <div className='text-left text-sm font-normal'>
            {firstName.substring(0, 1).toUpperCase()}.</div>
          <div className='text-left text-sm font-semibold'>
            {lastName.toUpperCase()}</div>
          <p className='text-right text-sm font-semibold'>
            {realScores}
          </p>
        </div>
        <div className='max-w-md pl-1 flex space-x-1'>
          {
            result === 0 ?
            <img className='mt-1 w-2 h-2 shadow-lg' alt="" src={require(`../layout/assets/red_dot.png`)} />
            : null
          }
          {
            result === 3 ?
            <img className='mt-1 w-2 h-2 shadow-lg' alt="" src={require(`../layout/assets/green_dot.png`)} />
            : null
          }
          <p className='text-left text-xs font-normal'>
            {eventString}
          </p>
        </div>
      </div>
    </>
  )
}

EventItem.propTypes = {
  tennisevent: PropTypes.object.isRequired,
}

export default EventItem
