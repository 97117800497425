import React from 'react'
import PeranaSportsLogo from '../components/layout/assets/PeranaSports_square.png'

function About() {
  const aboutYear = new Date().getFullYear()

  return (
    <>
      <img width={120} alt='' src={PeranaSportsLogo} />
      <h1 className='text-6xl mb-4'>TennisStats</h1>
      <p className='mb-4 text-2xl font-light'>
        Copyright &copy; {aboutYear} - Perana Sports
      </p>
      <p className='text-lg text-gray-400'>
        Version <span className='text-white'>1.0.0</span>
      </p>
    </>
  )
}

export default About