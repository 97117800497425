import { useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import MatchItem from './MatchItem'

function MatchList({ matches, playerId, closeMatchesList }) {
  // console.log('MatchList.jsx matches', matches)
  const navigate = useNavigate();
  const [_, forceUpdate] = useReducer((x) => x + 1, 0)

  const doFilter = () => {
    var arr = []
    var str = ""
    for (var i = 0; i < matches.length; i++) {
      var match = matches[i]
      if (match.selected) {
        if (str.length > 0) str += ","
        str += match.id
        arr.push(match)
      }
    }
    // console.log('arr', arr)
    // console.log('str', str)
    const goToFiltersPage = () => navigate(`/filtersanalysis/${str}/${playerId}`);
    goToFiltersPage()
  }

  const doSelectAll = () =>
  {
    {matches.map((match) => (
      match.selected = true
    ))}
    forceUpdate()
  }

  const doUnselectAll = () =>
  {
    {matches.map((match) => (
      match.selected = false
    ))}
    forceUpdate()
  }

  return (
    <>
      <div className="pb-2">
        <div className='flex flex-row justify-between pt-3 md:pt-1'>
          <div className="flex space-x-4">
            <img className='w-10 h-12 pt-2' alt="" width={32}
              src={require(`../layout/assets/filter_button.png`)}
              onClick={() => doFilter()}
            />
            <img className='w-10 h-12 pt-2' alt="" width={32}
              src={require(`../layout/assets/selectall_button.png`)}
              onClick={() => doSelectAll()}
            />
            <img className='w-10 h-12 pt-2' alt="" width={32}
              src={require(`../layout/assets/selectnone_button.png`)}
              onClick={() => doUnselectAll()}
            />
          </div>

          <img className='w-10 mr-2 h-12 pt-2 md:hidden' alt="" width={32}
            src={require(`../layout/assets/close_button.png`)}
            onClick={() => closeMatchesList()}
          />
        </div>
      </div>
      <div className="overflow-y-auto h-[80vh] md:h-[74vh]">
        <div className='pt-2 rounded-lg shadow-lg card-compact bg-base-100'>
          {matches.map((match) => (
            <MatchItem key={match.id} match={match} playerId={parseInt(playerId, 10)} />
          ))}
        </div>
      </div>
    </>
  )
}

MatchList.propTypes = {
  matches: PropTypes.array.isRequired,
}

export default MatchList
