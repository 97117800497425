import PropTypes from 'prop-types'
import PlayListItem from './PlayListItem'

function PlayListList({ playLists, handleItemClicked }) {
    console.log('PlayListList playLists', playLists)
  
  return (
    <div className='mx-2 rounded-lg shadow-lg card bg-base-100'>
      <div className=''>
        {playLists.map((playList) => (
          <PlayListItem key={playList.id} playList={playList} 
          handleItemClicked={(playList) => handleItemClicked(playList)} 
          />
        ))}
      </div>
    </div>
  )
}

PlayListList.propTypes = {
    playLists: PropTypes.array.isRequired,
}

export default PlayListList
