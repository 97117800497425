import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

function MatchItem({ match, playerId }) {
  const {
    id,
    guid,
    matchDateString,
    player1Id,
    player2Id,
    player1CountryCode,
    player1FirstName,
    player1LastName,
    player2CountryCode,
    player2FirstName,
    player2LastName,
    player1Scores,
    player2Scores,
    round,
    selected,
    tournamentName,
  } = match

  const toggleMatchSelect = () => {
    match.selected = !match.selected;
  }

  return (
    <>
      <div className='mb-1 rounded-md card-compact bg-base-200 hover:bg-base-300'>
        <div className="flex justify-between">
          <div className="flex space-x-2">
            <input type="checkbox" defaultChecked={selected} onChange={toggleMatchSelect} className="form-input mt-2 ml-2 px-2"></input>
            <img className='m-1 w-7 h-7' alt="" src={require(`../layout/assets/flags/${playerId === player1Id ? player2CountryCode : player1CountryCode}.png`)} />
            <p className='pt-2 text-md font-semibold'>
              <Link
                className='text-base-content hover:text-blue-500'
                to={`/player/${playerId === player1Id ? player2Id : player1Id}`}
              >
                {playerId === player1Id ? player2FirstName : player1FirstName} {playerId === player1Id ? player2LastName.toUpperCase() : player1LastName.toUpperCase()}
              </Link>
            </p>
          </div>
            <Link
              className='text-base-content'
              to={`/match/${id}`}
            >
              <img className='mr-2 pt-1' alt="" width={32} src={require(`../layout/assets/play_button.png`)} />
            </Link>
        </div>
        <p className="ml-2 text-sm font-semibold">{round} {tournamentName.toUpperCase()}</p>
        <div className="flex justify-between">
          <p className="ml-2 pb-1 text-sm font-semibold">{matchDateString}</p>
          <p className="mr-2 pb-1 text-sm font-semibold">{playerId === player1Id ? player1Scores : player2Scores}</p>
        </div>
      </div>
    </>
  )
}

MatchItem.propTypes = {
  match: PropTypes.object.isRequired,
}

export default MatchItem

    // <div className='mb-1 rounded-md card-compact bg-base-200 hover:bg-base-300'>
    //   <div className='card-body'>
    //     <div className='max-w-md flex'>
    //       <div className='pr-2 justify-start'>
    //         <img alt="" src={require(`../layout/assets/flags/${player1CountryCode}.png`)} />
    //       </div>
    //       <p className='mb-1 pt-1 text-l font-semibold'>
    //         <Link
    //           className='text-base-content'
    //           to={`/player/${player1Id}`}
    //         >
    //           {player1FirstName} {player1LastName.toUpperCase()}
    //         </Link>
    //       </p>
    //       <p className='mb-1 pt-1 text-l font-semibold text-right'>
    //         <Link
    //           className='text-base-content'
    //           to={`/player/${player2Id}`}
    //         >
    //           {player2FirstName} {player2LastName.toUpperCase()}
    //         </Link>
    //       </p>
    //       <div className='pl-2 justify-end'>
    //         <img alt="" src={require(`../layout/assets/flags/${player2CountryCode}.png`)} />
    //       </div>
    //     </div>
    //     <div className='max-w-md flex'>
    //       <input type="checkbox" defaultChecked={selected} onChange={toggleMatchSelect} className="form-input mt-2 px-2 py-2"></input>
    //       <p className="text-left text-gray-300 pl-6 pt-2">{matchDateString}</p>
    //       <p className="text-right text-gray-300 pr-2 pt-2">{round} {tournamentName}</p>
    //       <Link
    //         className='text-base-content'
    //         to={`/match/${id}`}
    //       >
    //         <img alt="" width={32} src={require(`../layout/assets/videoplay.png`)} />
    //       </Link>
    //     </div>
    //   </div>
    // </div>
