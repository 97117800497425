import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Navbar from './components/layout/Navbar'
import Footer from './components/layout/Footer'
import Home from './pages/Home'
import About from './pages/About'
import Player from './pages/Player'
import Match from './pages/Match'
import FiltersAnalysis from './pages/Filtersanalysis'
import Playlist from './pages/Playlist'
import FiltersVideo from './components/reports/FiltersVideo'
import NotFound from './pages/NotFound'
import { TennisStatsAPIProvider } from './context/tennisstatsapi/TennisStatsAPIContext'
import { AlertProvider } from './context/alert/AlertContext'
import { TennisEventProvider } from './context/tennisevent/TennisEventContext'

function App() {
  return (
    <TennisStatsAPIProvider>
      <AlertProvider>
        <TennisEventProvider>
          <Router>
            <div className='flex flex-col justify-between h-screen'>
              <Navbar />
              <main className='container mx-auto px-3 pb-12'>
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/about' element={<About />} />
                  <Route path='/player/:playerId' element={<Player />} />
                  <Route path='/match/:matchId' element={<Match />} />
                  <Route path='/filtersanalysis/:matchIds/:playerId' element={<FiltersAnalysis />} />
                  <Route path='/playlist/:playerId' element={<Playlist />} />
                  <Route path='/filtersvideo' element={<FiltersVideo />} />
                  <Route path='/notfound' element={<NotFound />} />
                  <Route path='/*' element={<NotFound />} />
                </Routes>
              </main>
              <Footer />
            </div>
          </Router>
          <ToastContainer />
        </TennisEventProvider>
      </AlertProvider>
    </TennisStatsAPIProvider>
  );
}

export default App;
